import { Injectable } from '@angular/core';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';
import { urlType } from 'src/app/core/models/urlType';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

export interface TimeLine {
  date: string;
  description: string;
  source: string;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  _windowRef: any = [];
  /** Instance of BehaviourSubject of type Timeline */
  timelineDetails = new BehaviorSubject<{ timeLines: Array<TimeLine>, count: number }>(null);
  getDoc = new BehaviorSubject<any>(null);
  documentData= this.getDoc.asObservable();

  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly languageTranslationService:LanguageTranslationService,
    private readonly spinner: NgxSpinnerService,
    private cookieService: CookieService
    ) { }

  getTimeLine(orderRequestId: string,lang: string): Observable<{ timeLines: Array<TimeLine>, count: number }> {    
    const url = this.languageTranslationService.addURLParamForSupportedLanguage(`/v1/timeline?orderRequestId=${orderRequestId}`,lang);
    return this.baseClientService.get(url).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty = null;
        return of(empty);
      })
    );
  }

  /**
     * To get updated timeline data
     * @param orderRequestId : Order Request Id
  */
  getUpdatedTimeline(orderRequestId, lang:string) {
      this.getTimeLine(orderRequestId, lang).subscribe(data => {
          if (data) {
              data.timeLines.reverse(); // Job start date to be displayed as last task in timeline
              this.timelineDetails.next(data);
          }
      });
  }


  getTasks(): Observable<{ policyCall: { status: 'not started' | 'scheduled' }, family: { status: boolean } }> {
    return this.baseClientService.get(`/v1/tasks`)
      .pipe(
        map(r => r.body), catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }

  getDocument(orderRequestId): Observable<any> {
    
    let httpParams: HttpParams = new HttpParams();
    const isCoBrowse = this.cookieService.get('transferee-context') ? true : false;
    httpParams = httpParams.append('orderRequestId', orderRequestId)
    isCoBrowse ? httpParams = httpParams.append('isCoBrowse', String(isCoBrowse)) : null;
    return this.baseClientService.get(`/v1/admin/document-management?`+ httpParams.toString(),"",urlType.integrationApi, false)
      .pipe(
        map(r => r.body), catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }

   /** Returns a token for CartusOnline authentication */
   getColToken(): Observable<any> {
    return this.baseClientService
      .get<any>('/v1/navigateToCOL', 'get the CartusOnline token')
      .pipe(
        map(r => r),
        catchError((err, source) => {
          const empty: any = null;
          return of(empty);
        })
      );
  }

  public get windowRef() {
    return this._windowRef;
  }

  public set windowRef(value: any) {
    if (value) {
      this._windowRef.push(value);
    }
  }

   /** DMS download file related function */
   getDMSDetails(orderRequestId, dmsData){
    const filetype = dmsData.documentType;
    const docId = dmsData.documentID;
    let baseURL = `/v1/admin/document-management/guid?docType=${filetype}&docId=${docId}&orderRequestId=${orderRequestId}`;
    return this.baseClientService.get<any>(baseURL,"",urlType.integrationApi, true).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to get DMS file: ', err);
        this.spinner.hide();
        return of(empty);
      })
    );
  }
  
  /** DMS download file related function */
  getDMSDowloadFile(dmsDetails){
    const guid = sessionStorage.getItem('car-ses-guid');
    const docId = dmsDetails.queryParams.docId;
    let baseURL = `/v2.0/onbase/hsdocument?guid=${guid}&docId=${docId}`;
    return this.baseClientService.getDMS(baseURL).pipe(
      map(r => r), catchError((err, source) => {
        const empty: any = null;
        this.spinner.hide();
        console.log('Failed to get DMS download file: ', err);
        return of(empty);
      })
    );
  }

  /* get DMS Data */
  downloadDMSFile(orderRequestId,dmsData) {
    this.spinner.show();
    this.getDMSDetails(orderRequestId, dmsData)
      .subscribe(
        data => {
          if (data && data.request) {
            const dmsDetails = data.request;
            const token = dmsDetails.headers.Authorization;
            const guid = dmsDetails.queryParams.guid;
            sessionStorage.setItem('car-ses-dms', token);
            sessionStorage.setItem('car-ses-guid', guid);
            this.getDMSDowloadFile(dmsDetails)
            .subscribe(
              data => {
                if (data) {
                  if (data && data.headers && data.headers.get('content-disposition')) {
                    let filename = JSON.parse(data.headers.get('content-disposition').split(';')[1].split('=')[1]);
                    filename = filename.slice(filename.indexOf('-') + 1).trim();
                    let blob: Blob = new Blob([data.body], {type: 'application/pdf'});
                    let a = document.createElement('a');
                    a.download = filename;
                    a.href = window.URL.createObjectURL(blob);
                    a.click();
                    this.spinner.hide();
                  }
                }
              }, err => {
                this.spinner.hide();
              })
          }
        }, err =>{
          this.spinner.hide();
        });
  }

    
  /** get amount,currency, eligiliblity date for lumpsum moveorder */
  getLSPaymentData(){
    let baseURL = `/v1/lumpsum/payment-eligibility`;
    return this.baseClientService.getLSPaymentData(baseURL).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty = null;
        return of(empty);
      })
    );
  }

  callRepaymentAgreementNotificationEmail(payload) : Observable<any> {
    return this.baseClientService.post(`/v1/lumpsum/repayment-agreement-notification`, payload).pipe(
      map(r => r.body),
      catchError(err => {
        console.log('Failed to call callRepaymentAgreementNotificationEmail', err);
        const emptyResp = null;
        return of(emptyResp);
      })
    )
  }

}
