
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import {
  HashLocationStrategy,
  LocationStrategy,
  DatePipe
} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { PrivacyNoticeComponent } from './core/components/privacy-notice/privacy-notice.component';
import { NonCompatibleComponent } from './core/components/non-compatible/non-compatible.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { FilterModalComponent } from './core/components/filter-modal/filter-modal.component';
import { HeaderComponent } from './core/components/header/header.component';
import { PrivacyTermsComponent } from './public/privacy-terms/privacy-terms.component';
import { LogoutComponent } from './core/components/logout/logout.component';
import { LoginComponent } from './core/components/login/login.component';
import { ToastIdleTimeoutComponent, FormatTimePipe } from './core/components/toast-idle-timeout/toast-idle-timeout.component';
import { ExternRouteComponent } from './core/components/extern-route/extern-route.component';
import { HttpInterceptorService } from './core/services/http-interceptor.service';
import { DialogComponent } from './public/dialog/dialog.component';

// tslint:disable-next-line: max-line-length
// tslint:disable-next-line: max-line-length
import { BenefitsDetailModalComponent } from './public/benefits/modules/list/components/benefits-detail-modal/benefits-detail-modal.component';
import { AppInitService } from './core/services/app-init.service';
import { PolicyModalComponent } from './public/dashboard/components/policy-modal/policy-modal.component';
import { NotificationComponent } from './core/components/notification/notification.component'
import { ConfirmBenefitsDialogComponent } from './public/benefits/modules/review/review.component';
import { BenefitService } from './public/benefits/services/benefit.service';

// Translate Modules
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import { SharedModule } from './core/shared/shared.module';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { DocumentLibraryPageComponent } from './public/document-library-page/document-library-page.component';
import { CoBrowsingTimerComponent } from './core/components/co-browsing-timer/co-browsing-timer.component';

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  };
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        ExternRouteComponent,
        PrivacyNoticeComponent,
        NonCompatibleComponent,
        FooterComponent,
        FilterModalComponent,
        HeaderComponent,
        PrivacyTermsComponent,
        NotificationComponent,
        ToastIdleTimeoutComponent,
        FormatTimePipe,
        NotificationComponent,
        DocumentLibraryPageComponent,
        CoBrowsingTimerComponent
    ],
    imports: [
        OAuthModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        SharedModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatSidenavModule,
        MatExpansionModule,
        MatIconModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatDialogModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatToolbarModule,
        MatListModule,
        MatStepperModule,
        MatRadioModule,
        MatGridListModule,
        MatDatepickerModule,
        MatCardModule,
        FormsModule,
        MatSnackBarModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatTabsModule,
        MatSortModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatNativeDateModule,
        MatDividerModule,
        // MaterialModule,
        NgxSpinnerModule,
        MatSlideToggleModule,
        MatBottomSheetModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule, // I must be last!! https://angular.io/guide/router#module-import-order-matters
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AppInitService,
        BenefitService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [
                AppInitService
            ],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        DatePipe,
        CookieService,
        BenefitService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
