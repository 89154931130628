<div class="header-outer-wrapper" [ngClass]="isLumpSumMoveOrder && showLumpSumHeader ? 'lumpsum-theme-header' : ''" fxLayout="column">
    <h1 style="text-indent: -9999px; position: absolute !important;" role="heading" aria-level="1">{{ !isLumpSumMoveOrder && !showLumpSumHeader ? 'Benefits Builder' : 'Lump Sum'}} </h1>
    <!-- Benefits Builder Header-->
    <div class="header-wrapper" *ngIf="!isLumpSumMoveOrder && !showLumpSumHeader" fxLayoutAlign.xs="space-between stretch" fxLayoutAlign.sm="space-between stretch">
        <div  [ngClass]="isNeedsAssessment?'assess-benefits-builder':'benefits-builder'" fxLayout="row"
            fxLayoutAlign="space-between center">
            <!-- <mat-icon fxHide=true fxHide.sm=false fxHide.xs=false aria-hidden="false" aria-label="dehaze icon" class="dehaze-icon" fxFlex="20%" (click)="openNav()">dehaze</mat-icon> -->
            <button *ngIf="!isNeedsAssessment" (click)="openNav()" fxHide=true fxHide.xs=false class="dehaze"
                aria-label="sidenav-button">
                <img src="../../../../assets/images/dehaze.png" alt="dehaze" />
            </button>
            <img src="../../../../assets/images/logos/BB-Logo.svg" alt="benefits_builder" role="img" width="80%"
                height="80%" fxFlex.sm="101px" fxFlex.xs="101px" />
        </div>
        <div fxLayout="row">
            <div *ngIf="!isNeedsAssessment" fxLayoutAlign="space-around center" fxHide=true fxHide.xs=false
                fxLayoutGap="26px">
                <!-- <div class="user-icon-wrapper" fxLayout="row">
                    <img src="../../../../assets/images/user-icon.svg" alt="user" class="user" fxHide=true fxHide.xs=false/>
                </div> -->
                <!-- <div class="notification-wrapper">
                    <mat-icon aria-hidden="false" aria-label="notification icon" class="notification-icon">notifications
                    </mat-icon>
                    <span class="red-circle"></span>
                </div> -->
            </div>
            <div class="right-content" fxLayout="row" fxLayoutAlign="start center"
                *ngIf="initials && username && !isNeedsAssessment">
                <!-- <div class="designation" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxHide.xs=true (mouseover)="onHover=true" (mouseout)="onHover=false">
                    <img [src]="onHover?'../../../../assets/images/cartus-consultant-hovered.svg':'../../../../assets/images/cartus-consultant.svg'" alt="user" class="user" />
                    <span>Cartus Consultant</span>
                </div> -->
                <div class="transferee-name" fxLayout="row" fxLayoutAlign="start none" *ngIf ='isConsultant'>
                    <div fxLayout="column"><div>Currently Viewing: &nbsp;</div></div>
                        <div fxLayout="column">
                            <div>{{fullName}}</div>
                            <div class="atlas-file">{{atlasFileNumber}}</div>
                        </div>
                </div>
                <div class="notification-wrapper" fxLayout="row">
                    <mat-icon #notificationMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menunotif"
                        (menuClosed)="notificationClosed()" class="notification-icon" aria-hidden="false"
                        aria-label="notification icon" tabindex="0" (keyup.enter)="onEnter()">notifications
                    </mat-icon>

                    <span *ngIf="listOfNotifications?.length>0" class="red-circle"></span>

                </div>

                <button fxHide.xs="true" mat-button [matMenuTriggerFor]="menu" title="{{ 'bb.needsAssessment.review.profileDropdown' | translate}}"
                    class="profile-dropdown" role="button">
                    <span class="profile-icon" role="none">{{ initials }}</span>
                    {{ username }} <mat-icon aria-hidden="false" aria-label="arrow icon">keyboard_arrow_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu" fxHide.xs="true" class="mat-menu">
                    <div class="selectedMove">
                       <p class="selectedLocation">{{latest.departureAddr.state}} , {{latest.departureAddr.countryCode}} > {{latest.destinationAddr.state}} , {{latest.destinationAddr.countryCode}}</p>
                         <p class="lastUpdate">{{'bb.needsAssessment.review.lastUpdate' | translate}}: {{latest.createdAt | date: 'yyyy-MM-dd'}}</p>
                    </div><!-- zeroth index/latestmove object -->
                    <button role="button" [routerLink]="['/profile-info', '0']" mat-menu-item aria-label="edit-profile"
                        class="edit-profile" fxLayout="row" fxLayoutAlign="start center" title="{{ 'bb.needsAssessment.faimilyInfo.editProfile' | translate}}">
                        <img class="menu-image" src="../../../../assets/images/person-blue.svg" alt="edit profile" />
                        {{ 'bb.needsAssessment.faimilyInfo.editProfile' | translate}}
                    </button>
                    <button role="button" [routerLink]="['/profile-info', '1']" mat-menu-item
                        aria-label="family-details" class="family-details" fxLayout="row" fxLayoutAlign="start center"
                        title="{{ 'bb.needsAssessment.faimilyInfo.familyDetails' | translate}}">
                        <img class="menu-image" src="../../../../assets/images/family.svg" alt="family details" />
                        {{ 'bb.needsAssessment.faimilyInfo.familyDetails' | translate}}
                    </button>
                     <!-- <div class="otherMoves"> // Commented as part of MOV360-4308 and MOV360-4320
                        {{ 'bb.needsAssessment.faimilyInfo.otherMoves' | translate}}
                       <button  mat-menu-item *ngFor="let move of older; let i = index" (click)="getDetails(move);" >{{move.departureAddr.state}} , {{move.departureAddr.countryCode}} > {{move.destinationAddr.state}} , {{move.destinationAddr.countryCode}}
                        <mat-icon aria-hidden="false" class="iconStyle" aria-label="arrow icon">navigate_next</mat-icon>
                        </button> -->
                        <!-- <button (click)="getDetails()">click</button> -->
                    <!-- </div> -->
                    <!-- <button role="menuitem" mat-menu-item aria-label="lorem-ipsum" class="lorem-ipsum" fxLayout="row" fxLayoutAlign="start center">
                        <img class="menu-image" src="../../../../assets/images/Contact.svg" alt="lorem ipsum"/> Lorem Ipsum
                    </button> -->
                    <button role="button" mat-menu-item (click)="logout()" aria-label="logout" class="logout-btn"
                        fxLayout="row" fxLayoutAlign="start center" title="{{ 'bb.header.logout' | translate}}">
                        <img class="menu-image" src="../../../../assets/images/logout.svg" alt="logout" /> {{ 'bb.header.logout' | translate}}
                    </button>
                </mat-menu>
            </div>
            <!--Notification-->
            <mat-menu #menunotif="matMenu">
                <div mat-menu-item class="notification-tab">
                    <div class="wrap-close">
                        <h2 class="notification-header">{{'bb.notification.notifications' | translate}}</h2>
                        <img fxHide.lg=true fxHide.md=true src="../../../../assets/images/close-icon.svg"
                            alt="close-notification" />
                    </div>
                    <div class="scroll">
                        <div fxLayout='column' *ngIf="!listOfNotifications || listOfNotifications.length===0">
                            <p>{{'bb.notification.part5' | translate}}</p>
                        </div>
                        <ng-container *ngIf="listOfNotifications?.length > 0">
                            <mat-card class="notification-card" (click)="goToNotification(item)"
                            *ngFor="let item of listOfNotifications">
                                <mat-card-header class="header">
                                    <mat-card-title class="title">{{item.header}}</mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                    <p class="notification-content">
                                        {{item.message}}
                                    </p>
                                </mat-card-content>
                            </mat-card>
                        </ng-container>

                    </div>
                    <div>
                        <button mat-button class="btn-close-notification" fxHide.lg=true fxHide.md=true>{{'bb.familyCard.close' | translate}}</button>
                    </div>
                </div>
            </mat-menu>

            <div class="right-content-needs-assess" fxLayout="row" fxLayoutAlign="center center"
                *ngIf="isNeedsAssessment">
                <button (click)="logout()" aria-label="logout button" class="logout-button" fxLayout="row"
                    fxLayoutAlign="center center">
                    <span fxHide.xs=true fxHide.sm=true>{{ 'bb.header.logout' | translate }}</span>
                    <img src="../../../../assets/images/logout-white.svg" alt="logout-image" title="{{ 'bb.header.logout' | translate}}"
                        class="logout-image" />
                </button>
            </div>

            <!-- User Area (For Mobile) -->
            <!-- <div fxLayout="row" fxHide=true fxHide.sm=false fxHide.xs=false *ngIf="initials && username">
            <button mat-button [matMenuTriggerFor]="menu_small" class="profile-dropdown">
                <span class="profile-icon">{{ initials }}</span>
            </button>
            <mat-menu #menu_small="matMenu">
                <button mat-menu-item (click)="logout()">Logout</button>
                <button mat-menu-item>
                        <mat-icon aria-hidden="false" aria-label="person icon" class="edit-profile">person</mat-icon>
                        <span>Edit Profile</span>
                    </button>
                <button mat-menu-item>
                        <mat-icon aria-hidden="false" aria-label="person icon" class="edit-profile">person</mat-icon>
                        <span>Edit Profile</span>
                    </button>
                <button mat-menu-item>
                        <mat-icon aria-hidden="false" aria-label="person icon" class="edit-profile">person</mat-icon>
                        <span>Edit Profile</span>
                    </button>
                <button mat-menu-item>
                        <mat-icon aria-hidden="false" aria-label="person icon" class="edit-profile">person</mat-icon>
                        <span>Edit Profile</span>
                    </button>
                <button mat-menu-item (click)="logout()">
                        <span>logout</span>
                        <mat-icon aria-hidden="false" aria-label="logout icon" class="edit-profile"></mat-icon>
                    </button>
            </mat-menu>
        </div> -->
            <!-- </div> -->
        </div>
    </div>

    <!-- LumpSum Header-->
    <div *ngIf="isLumpSumMoveOrder && showLumpSumHeader"  class="header-wrapper" fxLayoutAlign.xs="space-between stretch" fxLayoutAlign.sm="space-between stretch">
        <div fxLayout="row"
            fxLayoutAlign="space-between center">
            <!-- <mat-icon fxHide=true fxHide.sm=false fxHide.xs=false aria-hidden="false" aria-label="dehaze icon" class="dehaze-icon" fxFlex="20%" (click)="openNav()">dehaze</mat-icon> -->
            <button *ngIf="!isNeedsAssessment" (click)="openNav()" fxHide=true fxHide.xs=false class="dehaze"
                aria-label="sidenav-button">
                <img src="../../../../assets/images/dehaze.png" alt="dehaze" />
            </button>
            <img src="../../../../assets/images/logos/lumpsumCartusLogo.svg" class="cartus-logo" alt="cartus_logo" role="img" width="80%"
                height="80%" fxFlex.sm="101px" fxFlex.xs="101px"  (click)="!isNeedsAssessment ? gotoLumpsumDashboard() : null"/>
            
        </div>
        <div fxLayout="row">
            <div *ngIf="!isNeedsAssessment" fxLayoutAlign="space-around center" fxHide=true fxHide.xs=false
                fxLayoutGap="26px">
                <!-- <div class="user-icon-wrapper" fxLayout="row">
                    <img src="../../../../assets/images/user-icon.svg" alt="user" class="user" fxHide=true fxHide.xs=false/>
                </div> -->
                <!-- <div class="notification-wrapper">
                    <mat-icon aria-hidden="false" aria-label="notification icon" class="notification-icon">notifications
                    </mat-icon>
                    <span class="red-circle"></span>
                </div> -->
            </div>
            <div class="right-content" fxLayout="row" fxLayoutAlign="start center"
                *ngIf="initials && username && !isNeedsAssessment">
                <!-- <div class="designation" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxHide.xs=true (mouseover)="onHover=true" (mouseout)="onHover=false">
                    <img [src]="onHover?'../../../../assets/images/cartus-consultant-hovered.svg':'../../../../assets/images/cartus-consultant.svg'" alt="user" class="user" />
                    <span>Cartus Consultant</span>
                </div> -->
                <!--Conatct cartus for lumpsum dashboard-->
                <div class="transferee-name" fxLayout="row" fxLayoutAlign="start none" *ngIf ="isLumpSumMoveOrder && showLumpSumHeader">
                    <div fxLayout="column">
                        <button mat-button title="Contact Cartus"
                            class="contact-cartus-btn" role="button" (click)="openConsultantInfoModal()">
                            <span role="none">Contact Cartus</span>
                        </button>
                    </div>
                </div>

                <div class="transferee-name consultant-current-view"  fxLayout="row" fxLayoutAlign="start none" *ngIf ='isConsultant'>
                    <div fxLayout="column"><div>Currently Viewing: &nbsp;</div></div>
                        <div fxLayout="column">
                            <div>{{fullName}}</div>
                            <div class="atlas-file">{{atlasFileNumber}}</div>
                        </div>
                </div>
    

                <button fxHide.xs="true" mat-button [matMenuTriggerFor]="menu" title="{{ 'bb.needsAssessment.review.profileDropdown' | translate}}"
                    class="profile-dropdown" role="button">
                    <span class="profile-icon" role="none">{{ initials }}</span>
                    {{ username }} <mat-icon aria-hidden="false" aria-label="arrow icon">keyboard_arrow_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu" fxHide.xs="true" class="mat-menu lumpsum-theme-menu">
                    <div class="selectedMove">
                       <p class="selectedLocation">{{latest.departureAddr.state}} , {{latest.departureAddr.countryCode}} > {{latest.destinationAddr.state}} , {{latest.destinationAddr.countryCode}}</p>
                         <p class="lastUpdate">{{'bb.needsAssessment.review.lastUpdate' | translate}}: {{latest.createdAt | date: 'yyyy-MM-dd'}}</p>
                    </div><!-- zeroth index/latestmove object -->
                    <button role="button" [routerLink]="['/profile-info', '0']" mat-menu-item aria-label="edit-profile"
                        class="edit-profile" fxLayout="row" fxLayoutAlign="start center" title="{{ 'bb.needsAssessment.faimilyInfo.editProfile' | translate}}">
                        <img class="menu-image" src="../../../../assets/images/person-blue.svg" alt="edit profile" />
                        {{ 'bb.needsAssessment.faimilyInfo.editProfile' | translate}}
                    </button>
                    <button role="button" [routerLink]="['/profile-info', '1']" mat-menu-item
                        aria-label="family-details" class="family-details" fxLayout="row" fxLayoutAlign="start center"
                        title="{{ 'bb.needsAssessment.faimilyInfo.familyDetails' | translate}}">
                        <img class="menu-image" src="../../../../assets/images/family.svg" alt="family details" />
                        {{ 'bb.needsAssessment.faimilyInfo.familyDetails' | translate}}
                    </button>
                    <button role="button" mat-menu-item (click)="logout()" aria-label="logout" class="logout-btn"
                        fxLayout="row" fxLayoutAlign="start center" title="{{ 'bb.header.logout' | translate}}">
                        <img class="menu-image" src="../../../../assets/images/logout.svg" alt="logout" /> {{ 'bb.header.logout' | translate}}
                    </button>
                </mat-menu>
            </div>

            <div class="right-content-needs-assess" fxLayout="row" fxLayoutAlign="center center"
                *ngIf="isNeedsAssessment">
                <button (click)="logout()" aria-label="logout button" class="logout-button" fxLayout="row"
                    fxLayoutAlign="center center">
                    <span fxHide.xs=true fxHide.sm=true>{{ 'bb.header.logout' | translate }}</span>
                    <img src="../../../../assets/images/logout-white.svg" alt="logout-image" title="{{ 'bb.header.logout' | translate}}"
                        class="logout-image" />
                </button>
            </div>

            <!-- User Area (For Mobile) -->
            <!-- <div fxLayout="row" fxHide=true fxHide.sm=false fxHide.xs=false *ngIf="initials && username">
            <button mat-button [matMenuTriggerFor]="menu_small" class="profile-dropdown">
                <span class="profile-icon">{{ initials }}</span>
            </button>
            <mat-menu #menu_small="matMenu">
                <button mat-menu-item (click)="logout()">Logout</button>
                <button mat-menu-item>
                        <mat-icon aria-hidden="false" aria-label="person icon" class="edit-profile">person</mat-icon>
                        <span>Edit Profile</span>
                    </button>
                <button mat-menu-item>
                        <mat-icon aria-hidden="false" aria-label="person icon" class="edit-profile">person</mat-icon>
                        <span>Edit Profile</span>
                    </button>
                <button mat-menu-item>
                        <mat-icon aria-hidden="false" aria-label="person icon" class="edit-profile">person</mat-icon>
                        <span>Edit Profile</span>
                    </button>
                <button mat-menu-item>
                        <mat-icon aria-hidden="false" aria-label="person icon" class="edit-profile">person</mat-icon>
                        <span>Edit Profile</span>
                    </button>
                <button mat-menu-item (click)="logout()">
                        <span>logout</span>
                        <mat-icon aria-hidden="false" aria-label="logout icon" class="edit-profile"></mat-icon>
                    </button>
            </mat-menu>
        </div> -->
            <!-- </div> -->
        </div>
    </div>

    <!-- Notification div -->
    <!-- <div class="notification-div" fxLayout="row">
        <img src="../../../../assets/images/bell-icon.svg" alt="bell-icon" />
        <p>Please schedule your policy call with your consultant as soon as possible. <a class="notification-click">Click Here</a></p>
    </div> -->
</div>