<div class="consultant-info-modal-wrap">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mat-dialog-title">
        <h2>Consultant Details</h2><button mat-icon-button mat-dialog-close><span
                class="material-icons close-icon">close</span></button>
    </div>
    <div class="mat-dialog-content">
        <div  fxLayout fxLayoutAlign="space-between center" fxLayoutAlign.gt-md="flex-start"
        fxLayoutGap.gt-md="25px" class="consulant-info-content" *ngIf="consultantDetails">
            <div fxLayout="row" fxFlex="100%">
                <div fxFlex="50%">
                    <strong>Consultant Name:</strong>
                </div>
                <div fxFlex="50%">
                    <span>{{consultantDetails?.firstName}} {{consultantDetails?.lastName}}</span>
                </div>
            </div>
            <div fxLayout="row" fxFlex="100%">
                <div fxFlex="50%">
                    <strong>Consultant Phone Number:</strong>
                </div>
                <div fxFlex="50%">
                    <span>{{consultantDetails?.phoneDetailsList[0]?.phoneDialingCode}} {{consultantDetails?.phoneDetailsList[0]?.phoneNumber}}</span>
                </div>
            </div>
            <div fxLayout="row" fxFlex="100%">
                <div fxFlex="50%">
                    <strong>Consultant Email:</strong>
                </div>
                <div fxFlex="50%">
                    <span><a href="mailto:+{{consultantDetails?.emailDetailsList[0]?.emailAddress}}">{{consultantDetails?.emailDetailsList[0]?.emailAddress}}</a></span>
                </div>
            </div>
            <div fxLayout="row" fxFlex="100%">
                <div fxFlex="48%">
                    <strong>Consultant TimeZone:</strong>
                </div>
                <div fxFlex="52%">
                    <span>{{consultantDetails?.timeZone}}</span>
                </div>
            </div>
           
        </div>
        <div  fxLayout fxLayoutAlign="space-between center" fxLayoutAlign.gt-md="flex-start"
        fxLayoutGap.gt-md="25px" class="consulant-info-content" *ngIf="!consultantDetails && consultantNotAssigned">
            <div fxLayout="row" fxFlex="100%">
               <p>{{'bb.alertMessages.consultantErrorMessage' | translate}}</p>
            </div>
        </div>
    </div>
    <div class="mat-dialog-actions">
        <div class="filter-buttons" fxLayout="row" fxLayoutAlign="end center">
            <button mat-button (click)="close()" class="close-btn"> Close </button>
        </div>
    </div>
</div>