import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';
import { TasksInfoService } from 'src/app/core/services/tasks-info.service';

@Component({
  selector: 'app-consultant-info-modal',
  templateUrl: './consultant-info-modal.component.html',
  styleUrls: ['./consultant-info-modal.component.scss']
})
export class ConsultantInfoModalComponent implements OnInit {

  consultantDetails: any;
  consultantNotAssigned: boolean = false;
  browserLanguage: string;
  constructor(
    public dialogRef: MatDialogRef<ConsultantInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly tasksService: TasksInfoService,
    public spinner: NgxSpinnerService,
    private readonly languageTranslationService: LanguageTranslationService,
    public translate: TranslateService) { 
      this.browserLanguage = this.languageTranslationService.getSupportedLanguage();
      translate.use(this.browserLanguage);
    }

  ngOnInit(): void {
    this.spinner.show();
    this.tasksService.getTasks().subscribe(tasks => {
        this.spinner.hide();
        if (tasks && tasks?.policyCall?.consultantDetails) {
          this.consultantDetails = tasks.policyCall.consultantDetails;
        } else {
          this.consultantNotAssigned = true;
        }
    });
  }

  close() {
    this.dialogRef.close();
  }

}
