<div class="document-library" fxLayout="column" role="none">
    <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100%" class="back-btn-wrapper" role="none">
        <div class="header-left" role="none">
            <button class="back-btn" (click)="goToDashboard()" role="button" aria-label="back to benefit lists">
                <img class="backspace-icon" title="{{'bb.needsAssessment.review.back' | translate}}" alt="back-arrow"
                    src="../../../assets/images/back-arrow.svg" />
            </button>
            <span class="title-style" fxFlex="100%" role="heading" aria-level="1" aria-label="Document Library">Document
                Library</span>
        </div>
    </div>
    <div class="scroll-content" fxLayout="row" fxLayoutGap="32px" fxLayoutGap.xs="12px">
        
        <div *ngFor="let doc of docs;let indx = index" class="doc"
            fxLayout="column" fxFlex="22%" fxFlex.xs="45%">
            <div class="type">
                <img  width="48px" height="64px" src="../../../../../assets/images/icon_PDF.svg" alt="benefits_builder"
                    role="img" />
                <!-- <img *ngIf="doc.type == 'word'" width="48px" height="64px" src="../../../../../assets/images/icon_Word.svg" alt="benefits_builder"
                    role="img" /> -->
            </div>
            <p class="title" (click)="openDownloadToastr(doc)">{{doc.documentType}}</p>
            <div class="ver">
                <!-- <p *ngIf="doc.version>1" class="ver-text">Version: <span class="ver-ed">{{doc.version}}</span><mat-form-field appearance="none" >
                    <mat-select *ngIf="doc.version == 2">
                        <mat-option *ngFor="let version of docVersions2" role="listbox" [value]="version.value">
                            <img src="../../../../../assets/images/material-file-download-blue.svg"
                                alt="download" />
                            {{version.date}}
                        </mat-option>
                    </mat-select>
                    <mat-select *ngIf="doc.version == 3">
                        <mat-option *ngFor="let version of docVersions3" role="listbox" [value]="version.value">
                            <img src="../../../../../assets/images/material-file-download-blue.svg"
                                alt="download" />
                            {{version.date}}
                        </mat-option>
                    </mat-select>
                </mat-form-field></p> -->
                <p  class="ver-text">Doc ID: <span class="ver-ed">{{doc.documentID}}</span></p>
            </div>
            <p class="date">Date: {{doc.documentDate | date: 'yyyy-MM-dd'}}</p>
            <!-- <div *ngIf="indx == 0" class="new-doc">New!</div> -->
        </div>
    
    </div>
</div>